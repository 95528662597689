$(function () {
  window.Geenie.ProjectsInvestment = {
    get_promise: function () {
      var project_id = $('#project_id').val();
      return $.ajax({
        type: 'get',
        dataType: 'json',
        url: '/api/projects/' + project_id + '/investment-info',
        cache: false
      }).done(function (data) {
        var $collectedMoney = $('[data-investment-info-collected-money]');
        $collectedMoney.text(data.collected_money + '円');
        var $percentStyle = $('[data-investment-info-percent-style]');
        $percentStyle.attr('style', 'width:' + data.percent + '%;');
        var $percent = $('[data-investment-info-percent]');
        $percent.text(data.percent + '%');
        var $supporter = $('[data-investment-info-collected-supporter]');
        $supporter.text(data.collected_supporter + '人');
        $.each(data.projects_returns, function () {
          var $return = $('#return' + this.id);

          if (!this.is_nolimit) {
            var $remainDetail = $return.find('[data-investment-info-return-remain-detail]');
            $remainDetail.text(this.remain);

            if (this.remain) {
              var $remain = $return.find('[data-investment-info-return-remain]');
              $remain.text('残り' + this.remain + '個');
            }
            if (this.remain >= 0 && this.remain <= 10) {
              var $remain = $return.find('[data-investment-info-return-remain]');
              $remain.text('残り' + this.remain + '個');
              $remainDetail.css('color', '#fb2a79');
            }
          }
          var $supportedAmount = $return.find('[data-investment-info-return-supported-amount]');
          $supportedAmount.text(this.supported_amount + '人');
          if (!this.can_purchase) {
            $return.find('[data-investment-info-return-link-to-select-btn="end-of-reception"]').show();
          } else {
            if(this.is_soldout) {
              $return.find('[data-investment-info-return-link-to-select-btn="sold-out"]').show();
            } else {
              $return.find('[data-investment-info-return-link-to-select-btn="not-sold-out"]').show();
            }
          } 
        });
      });
    }
  };
});
